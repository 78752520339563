import { NextRouter, useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import AuthContext from '../lib/context/auth/AuthContext';
import { employeesRoute, loginRoute } from '../lib/routes/routes';

const AuthComponent = () => {
  const router: NextRouter = useRouter();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.authenticated) {
      router.push(employeesRoute);
    }
    if (!auth.authenticated) {
      router.push(loginRoute);
    }
  }, [auth.authenticated, router]);

  return <div></div>;
};

export default AuthComponent;
